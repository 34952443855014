<template>
  <v-container
    fill-height
    :class="mainClass"
  >
    <v-row
      justify="center"
      align="center"
    >
      <v-col
        align="center"
        cols="12"
      >
        <v-progress-circular
          indeterminate
          :color="color"
          :size="size"
        >
          <v-img
            :max-width="size - 20"
            :src="logo"
          />
        </v-progress-circular>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  data() {
		return {
			showsAppBar: false
		}
	},
	computed: {
		size() {

			if(this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs) {
				return '100'
			}

			return '200'
		},
		color() {

			if(this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs) {
				return 'white'
			}

			return 'primary'
		},
		mainClass() {
			const classes = []

			if(this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs) {
				classes.push('primary')
			}

			return classes
		},
		logo() {

			if(this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs) {
				return 'https://cdn.salescloud.is/logo_white.svg'
			}

			return 'https://cdn.salescloud.is/logo.svg'
			}
	},
	mounted() {
		this.showsAppBar = this.$store.state.showAppBar
		this.$store.commit('updateShowAppBar', false)
	},
	destroyed() {

		if(this.$store.state.inApp === false && this.$store.state.inIframe === false) {
			this.$store.commit('updateShowAppBar', false)
			return
		}

		this.$store.commit('updateShowAppBar', this.showsAppBar)
	}
}
</script>
